$(document).ready(function () {

	$('#btn-login').on( 'click', function(e) {
		e.preventDefault();
		$('#form-login').submit();
	});
	
	$('#form-login').keypress(function(e) {

       if (e.which === 13) {

            e.preventDefault();

            if (!$('#btn-login').hasClass('disabled')) {
                $('#form-login').submit();
            }
          
            return false;
       }
    });

    $('#form-login').validate({
        errorClass: 'has-error',
        ignore: [],
        rules: {
            'login': {required: true}/**,
            'password': {required: true}*/
        },
        messages: {
            'login': ''/*,
            'password':*/ 
        },
        submitHandler: function (form) {

            var btn = $('#btn-login');

            lockBtn(btn);

            $.ajax({
                type: 'POST',
                dataType: 'json',
                data: $('#form-login').serialize(),
                url: $('#form-login').attr("action"),
                success: function (response) {
                    var result = response.result;
                    var message = response.message;
                    if (result > 0) {
                    	return self.location = cstPath.ROOT + 'page.php';
                    } 
                    else {
                        if (response.hasOwnProperty('logged')) {
                            swal({
                                title: 'Identification',
                                html: message,
                                type: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor : '#FC3',
                                showCancelButton: true,
                                reverseButtons: true,
                                cancelButtonText: 'Annuler'
                            }).then(function(confirm) {

                                if (confirm.value) {

                                    $.ajax({
                                        type: 'POST',
                                        dataType: 'json',
                                        data: $('#form-login').serialize() + '&force_login=1',
                                        url: $('#form-login').attr("action"),
                                        success: function (response) {
                                            var result = response.result;
                                            var message = response.message;
                                            if (result > 0) {
                                                return self.location = cstPath.ROOT + 'page.php';
                                            } 
                                            else {

                                                notifyFail(message, 'Identification');
                                            }

                                            unlockBtn(btn);
                                        },
                                        error: function(error) {

                                            notifyError(error);
                                        }
                                    });
                                }
                                else {

                                    unlockBtn(btn);
                                }
                            });
                        }
                        else {

                            notifyFail(message, 'Identification');
                            unlockBtn(btn);
                        }
                    }
                },
                error: function(error) {

                    notifyError(error);

                    unlockBtn(btn);
                }
            });
        }
    });
});
